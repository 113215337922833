<template>
  <div class="login-page">
    <v-row justify="center" align="center">
      <v-col class="text-center">
        <div class="image">
          <img src="../assets/images/logo.png" alt="main logo" />
          <!-- <vuetify-logo /> -->
        </div>
        <h2 v-if="paid && !loading" style="color: #44a04b">
          شكراً لك ، تمت عملية الدفع بنجاح
        </h2>
        <div v-if="!paid && !loading">
          <h2 style="color: #930f0f">للأسف فشلت عملية الدفع</h2>
          <h2>
            لاعادة المحاولة اضغط
            <router-link :to="'/paymentMethod?orderId=' + order_id"
              >هنا</router-link
            >
          </h2>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { API_URL } from "../config/index.js";
import axios from "axios";
export default {
  data() {
    return {
      loading: true,
      paid: false,
      order_id: "",
    };
  },
  mounted() {
    axios
      .get(`${API_URL}/online-payment/check/` + this.$route.params.paymentId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.data.status.error == false) {
          if (response.data.data.status == "success") {
            this.paid = true;
          }
          this.order_id = response.data.data.order_id;
        }
        this.loading = false;
      });
  },
};
</script>

<style></style>
